<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="充值成功"
                   left-arrow
                   @click-left="$router.back()" />
    </div>
    <div class="main"
         v-show="!show2">
      <div class="box"
           v-if="isshow">
        <img style="width:70px;height:70px"
             src="../assets/images/ok.png"
             alt />
        <div style="color:#333333;font-size:14px;">充值成功</div>
        <div style="color:#666;font-size:12px">
          账号{{this.phone}}当前余额为
          <span>{{this.integral}}</span>元
        </div>
      </div>
      <div class="box"
           v-if="!isshow">
        <img style="width:70px;height:70px"
             src="../assets/images/cancelOrder.png"
             alt />
        <div style="color:#333333;font-size:14px;">充值失败</div>
        <div style="color:#666;font-size:12px">
          账号{{this.phone}}当前余额为
          <span>{{this.integral}}</span>元
        </div>
      </div>
      <!-- <div class="imgbox">
        <img src="../assets/images/banner.png" alt />
      </div>-->
      <div class="foot"
           @click="go">
        <button class="btn">
          <img style="width:15px;height:15px"
               src="../assets/images/index.png"
               alt /> 返回首页
        </button>
      </div>
    </div>
    <div class="main2"
         style="background:#eee;width:100%;height:100%; display: flex;
        justify-content: center;
        align-items: center;"
         v-show="show2">
      <van-loading type="spinner"
                   color="#1989fa"
                   size="24px" />
    </div>
    <!-- 订单确认弹窗 -->
    <!-- <van-dialog
      :closeOnClickOverlay="true"
      :showCancelButton="true"
      @confirm="DialogSure.confirm(1)"
      @cancel="DialogSure.confirm(0)"
      confirmButtonText="已完成支付"
      title="支付确认"
    >
      <section class="confirm_dialog">
        请在微信内完成支付，如果您已支付成功，请点击"已完成支付"按钮。
      </section>
    </van-dialog>-->
  </div>
</template>
<script>
import { Toast } from 'vant'
import { loadstatus_api } from '../http/api/pay'
import { newinfo_api } from '../http/api/certification'

export default {
  name: 'TaxiH5Cancel',

  data () {
    return {
      phone: '',
      integral: '',
      show: false,
      orderNum: '',
      isshow: true,
      show2: true
    }
  },

  mounted () {
    this.orderNum = localStorage.getItem('orderNum')
    console.log(this.orderNum)
    if (this.orderNum != null) {
      setTimeout(() => {
        this.orderstatus()
      }, 2000)
    } else {
      this.$router.push({
        path: '/index'
      })
    }
    this.newinfo()
  },

  methods: {
    async orderstatus () {
      console.log(111111)
      let res = await loadstatus_api({
        orderNum: this.orderNum,
      })
      if (res.code == 200) {
        if (res.data == false) {
          localStorage.removeItem('orderNum')
          // Toast('充值失败');
          this.show2 = false
          this.isshow = false
          // clearInterval(this.timerID);
          // this.$router.push({
          //   path:'/Rech'
          // })
        } else {
          localStorage.removeItem('orderNum')
          this.isshow = true
          this.show2 = false

        }
      }
    },
    //确认
    confirm () {
      this.$router.push({
        path: '/home',
      })
    },
    //取消
    cancel () {
      this.$router.go(-1) //返回上一层
    },
    //最新用户信息
    async newinfo () {
      let res = await newinfo_api({})

      if (res.code == 200) {
        // console.log(res.data.headImg)
        // var img = JSON.parse(sessionStorage.getItem('token'))
        // img.userName = res.data.userName
        // img.integral = res.data.integral
        // sessionStorage.setItem('token', JSON.stringify(img))
        this.integral = res.data.integral
        this.phone = res.data.phone
      }
    },
    //返回首页
    go () {
      this.$router.push({
        path: '/index',
      })
    },
    onClickLeft () {
      Toast('返回')
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;

  .main {
    margin-top: 30%;
    .box {
      text-align: center;
      line-height: 30px;
    }
    .foot {
      .btn {
        width: 90%;
        height: 50px;
        margin: 0 5%;
        background: #fff;
        border-radius: 15px;
        border: 0;
        margin-top: 50%;
        border: solid 1px #999;
        color: #999;
      }
    }
  }
  .imgbox {
    margin-top: 20px;
  }
}
</style>